// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import BlockParser from '../block-parser'
import '../block-parser/style.less'

import InsightPageWrapper from '../insight-page-wrapper'

import './style.less'

import Graph1 from '../graphs/graph1'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query InsightPageTemplateQuery($routeSlug: String) {
    allInsightsJson(filter: { routeSlug: { eq: $routeSlug } }) {
      edges {
        node {
          title
          routeSlug
          contentBlocks {
            type
            content
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allInsightsJson: {
          edges: [
            {
              node: {
                shortAbstract,
                title,
                routeSlug,
                publishedTimestamp,
                contentBlocks,
              },
            },
          ],
        },
      },
    } = this.props

    const dateTime = new Date(publishedTimestamp * 1000).toLocaleDateString(
      'en-US'
    )

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Insights', slug: '/insights' },
        { title, slug: routeSlug.substring(1) },
      ],
    }

    const now = Math.floor(new Date().getTime() / 1000)

    return (
      <InsightPageWrapper
        className="event-page"
        pageSchema={pageSchema}
        {...this.props}
      >
        <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
        <h1>{title}</h1>
        <div
          className="as-paragraph"
          style={{
            maxWidth: 'unset',
            borderRadius: '0.55rem',
            overflow: 'hidden',
          }}
        >
          <Graph1 />
        </div>
        {map(contentBlocks, (block) => (
          <BlockParser block={block} />
        ))}
      </InsightPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
