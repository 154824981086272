// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import moment from 'moment'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
// import sortedUniq from 'lodash/sortedUniq'
// import reverse from 'lodash/reverse'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query InsightPageWrapperQuery {
    allInsightsJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className = null, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allInsightsJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['publishedTimestamp', 'asc'])

      const {
        pageContext: { next, prev },
      } = rest

      return (
        <StandardPageWrapper
          className={classNames(className, 'insight-page', 'explorer')}
          {...rest}
        >
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              {children}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <aside
                style={{
                  borderTop: '4px solid var(--orange1)',
                  marginTop: '1.1rem',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                }}
              >
                <h2>Insights</h2>
                <p>
                  The Aura network generates thousands of data points every day,
                  and our team is proud to elevate data visualization into an
                  artform, and present unseen patterns, connections and stories
                  that exist within our data.
                </p>
                <p>
                  What we have found is that our community is fundamentally
                  inter-dependent and inter-arising, that differences do exist
                  between various groups within the community, and that these
                  differences do not ultimately serve the goals of the
                  community.
                </p>
                <ul>
                  {map(ordered, ({ title, routeSlug }) => (
                    <li>
                      <Link to={routeSlug}>{title}</Link>
                    </li>
                  ))}
                </ul>
              </aside>
            </Col>
          </Row>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
